import { Col, Container, Row } from 'react-bootstrap';
import pkg from '../../package.json';
import './Privacy.scss';

export const ReleaseNotes = () => (
  <Container>
    <Row className="privacy-page d-md-none">
      <Col style={{ textAlign: 'center' }}>
        <a href={pkg.downloadUrl} target="_blank" className="btn btn-lg btn-blue">
          Download PassCargo Desktop
        </a>
      </Col>
    </Row>
    <Row className="privacy-page">
      <Col>
        <h2>PassCargo Desktop Release Notes</h2>
        <h3 id="25.1.2">Version 25.1.2</h3>
        <p>
          <em>Release Date: March 24th, 2025</em>
        </p>
        <h4>New Features & Enhancements:</h4>
        <ul>
          <li>Aircraft position reporting</li>
        </ul>
        <h4>Fixes:</h4>
        <ul>
          <li>PMDG offsets fixes for light updates</li>
          <li>Performance enhancements</li>
          <li>Stability fixes</li>
        </ul>
        <hr />
        <h3 id="25.1.1">Version 25.1.1</h3>
        <p>
          <em>Release Date: March 5th, 2025</em>
        </p>
        <h4>New Features & Enhancements:</h4>
        <ul>
          <li>Flight reporting</li>
          <li>Flight failures tracking</li>
        </ul>
        <h4>Fixes:</h4>
        <ul>
          <li>Some stablity fixes in flight tracking and handling</li>
        </ul>
        <hr />
        <h3 id="25.1.0">Version 25.1.0</h3>
        <p>
          <em>Release Date: February 8th, 2025</em>
        </p>
        <h4>New Features & Enhancements:</h4>
        <ul>
          <li>Introduced dark theme</li>
          <li>Introduced pilot / attendants announcements</li>
          <li>Re-designed settings (added ability to set volume and announcements)</li>
          <li>Added integration with GSX PRO for boarding and deboarding procedures</li>
          <li>Re-design active flight view</li>
          <li>Added passengers to the flight (simulate)</li>
          <li>Added cargo flights processing</li>
        </ul>
        <h4>Fixes:</h4>
        <ul>
          <li>Metric vs imperial units for flying altitude tracking</li>
          <li>Start flight time on Live Map</li>
          <li>Relative time for flying in custom time for the Live Map</li>
          <li>Date/time displaying format</li>
        </ul>
        <hr />
        <h3 id="24.4.0">Version 24.4.0</h3>
        <p>
          <em>Release Date: September 20th, 2024</em>
        </p>
        <h4>New Features & Enhancements:</h4>
        <ul>
          <li>
            <strong>Redesigned Application:</strong>&nbsp;Experience a fresh and modern interface that enhances
            usability. Navigation is smoother, and key features are now more accessible, allowing for a more intuitive
            user experience.
          </li>
          <li>
            <strong>Refactored Flight Tracker:</strong>&nbsp;Our flight tracking functionality has been completely
            overhauled! The new implementation no longer requires WebSockets, resulting in faster performance and
            reduced resource consumption. Enjoy real-time updates without any hassle.
          </li>
          <li>
            <strong>Simplified Flows:</strong>&nbsp;We have streamlined various workflows to make your tasks more
            efficient. From booking to tracking, each process has been optimized for quicker completion and improved
            clarity.
          </li>
        </ul>
        <h4>Stability Fixes:</h4>
        <ul>
          <li>
            <strong>Numerous Stability Fixes and Enhancements:</strong>&nbsp; We have addressed various bugs and
            performance issues to ensure a more stable experience. This includes optimizations that enhance overall
            application reliability and speed, so you can focus on what matters most—your cargo management.
          </li>
        </ul>
      </Col>
      <Col md={4} className="d-none d-md-block">
        <a href={pkg.downloadUrl} target="_blank" className="btn btn-lg btn-blue">
          Download PassCargo Desktop
        </a>
        <h4>Releases</h4>
        <ul>
          <li>
            <a href="#25.1.2" className="version">
              v25.1.2
            </a>
          </li>
          <li>
            <a href="#25.1.1" className="version">
              v25.1.1
            </a>
          </li>
          <li>
            <a href="#25.1.0" className="version">
              v25.1.0
            </a>
          </li>
          <li>
            <a href="#24.4.0" className="version">
              v24.4.0
            </a>
          </li>
        </ul>
      </Col>
    </Row>
    <Row className="privacy-page">
      <hr />

      <h4>
        <em>
          Thank you for being a part of the PassCargo community!
          <br />
          We are committed to continuously improving our application based on your feedback.
        </em>
      </h4>
    </Row>
  </Container>
);
