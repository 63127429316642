import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollSpy from 'react-ui-scrollspy';

import { Banner } from 'components/Banner';
import { Desktop } from 'components/Desktop';
import { Download } from 'components/Download';
import { Footer } from 'components/Footer';
import { HeaderBox } from 'components/HeaderBox';
import { LatestNews } from 'components/LatestNews';
import { Overlay } from 'components/Overlay';
import { PrivacyPage } from 'components/Privacy';
import { ReleaseNotes } from 'components/ReleaseNotes';
import { TosPage } from 'components/Tos';

const Welcome = () => (
  <main>
    <ScrollSpy activeClass="active">
      <Banner />
      <Desktop />
      <Overlay />
      <Download />
      <LatestNews />
    </ScrollSpy>
  </main>
);

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <HeaderBox />
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/tos" element={<TosPage />} />
        <Route path="/release-notes" element={<ReleaseNotes />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
